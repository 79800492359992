import "./App.css";
import { useState, useEffect } from 'react';
import Navbar from "./components/Navbar";
import HomeView from "./components/HomeView";
import AboutView from "./components/AboutView";
import SearchView from "./components/SearchView";
import MovieView from "./components/MovieView";
import PageNotFoundView from "./components/PageNotFoundView";
import { Routes, Route } from "react-router-dom";

function App() {

  // searchResults is a variable ([]), while setSearchResults is the setter function
  // same with searchText ('') and setSearchText
  // understand the flow of searchResults and searchText and how they work and are moved to diffrent classes to make the search function work
  const [searchResults, setSearchResults] = useState([]);
  const [searchText, setSearchText] = useState('');

  // second parameter: what the function monitors for changes (runs function when the second parameter changes?)
  useEffect(() => {
    // only perform api request searchText exists (has a value)
    if (searchText) {
      fetch(`https://api.themoviedb.org/3/search/movie?api_key=b557e375b15abd7534b753e4f0b69d6a&query=${searchText}&include_adult=false&language=en-US&page=1`)
      .then(response => response.json())
      .then(data => {
        setSearchResults(data.results)
      })
    }

  }, [searchText])

  return (
    <div>
      <Navbar searchText={searchText} setSearchText={setSearchText} />
      <Routes>
        {/* Switch becomes Routes in react-router-dom v6 */}
        {/* https://medium.com/@manishsundriyal/whats-new-in-react-router-v6-20eefe665be9 */}
        <Route path="/" element={<HomeView />} />
        <Route path="/about" element={<AboutView />} />
        <Route path="/search" element={<SearchView keyword={searchText} searchResults={searchResults} />} />
        <Route path="/movies/:id" element={<MovieView />} />
        <Route path='*' element={<PageNotFoundView />}/>
      </Routes>
    </div>
  );
}

export default App;
