import Hero from "./Hero";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

const MovieView = () => {
  // gets movie id parameter from url
  // pulls data out of our url and into our application
  const { id } = useParams()
  const [ movieDetails, setMovieDetails ] = useState({})
  // adds "loading" message when api request isnt done yet
  const [ isLoading, setIsLoading ] = useState(true)

  // useEffect runs method whenever second parameter variable changes
  useEffect(() => {
    fetch(`https://api.themoviedb.org/3/movie/${id}?api_key=b557e375b15abd7534b753e4f0b69d6a&language=en-US`)
      .then(response => response.json())
      .then(data => {
        // setTimeout(): mimics a slow api: waiting for 2 seconds before data loads from api
        // setTimeout(() => {
        //   setMovieDetails(data)
        //   setIsLoading(false)
        // }, 2000)

        setMovieDetails(data)
        setIsLoading(false)
      })
  }, [id])

  function renderMovieDetails() {
    if (isLoading) {
      return <Hero text="Loading..." />
    }

    if (movieDetails) {
      // TODO: Deal with a possible missing image
      const posterPath = `https://image.tmdb.org/t/p/w500${movieDetails.poster_path}`
      const backdropUrl = `https://image.tmdb.org/t/p/original${movieDetails.backdrop_path}`

      return (
        <>
          <Hero text={movieDetails.original_title} backdrop={backdropUrl} />

          <div className="container my-5">
            <div className="row">
              <div className="col-md-3">
                {/* movie poster image */}
                <img src={posterPath} alt="..." className="img-fluid shadow rounded" />
              </div>

              <div className="col-md-9">
                <h2>{movieDetails.original_title}</h2>
                <p className="lead">
                  {movieDetails.overview}
                </p>
              </div>
            </div>
          </div>
        </>
      )

    }
  }


  // instead of returning a jsx statement, we return a function instead, which willl render based on if the data is loading or not
  return renderMovieDetails();
};

export default MovieView;
