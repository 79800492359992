import imagenotfound from "./../images/imagenotfound.png";
import Hero from "./Hero";
import { Link } from "react-router-dom";

// do i need to add key to the MovieCard parameter since i passed it in from the "return" line in SearchView?
const MovieCard = ({ movie }) => {
  let posterUrl = `${imagenotfound}`;
  if (movie.poster_path) {
    posterUrl = `https://image.tmdb.org/t/p/w500${movie.poster_path}`;
  }

  const detailUrl = `/movies/${movie.id}`;

  return (
    // <div className="card" style="width: 18rem;">
    // gives error: The `style` prop expects a mapping from style properties to values, not a string. For example, style={{marginRight: spacing + 'em'}} when using JSX.

    // if i have the above error, i must put style in terms of javascript like below, not regular html
    // <div className="card" style={{width: '18em', ''}}>

    <div className="col-lg-3 col-md-3 col-2 my-4">
      {/* but we dont need style rn, so we'll just do the below. instead, configure style in the above div line */}
      <div className="card">
        <img
          src={posterUrl}
          className="card-img-top"
          alt={movie.original_title}
        />
        <div className="card-body">
          <h5 className="card-title">{movie.original_title}</h5>
          <Link to={detailUrl} className="btn btn-primary">
            Show details
          </Link>
        </div>
      </div>
    </div>
  );
};

const MovieList = ({ searchResults }) => {
  // obj = each element in the array
  // i = index of element in array
  const resultsHtml = searchResults.map((obj, i) => {
    // give each element a key (give each element all parameters passed in the map) to make react happy
    return <MovieCard movie={obj} key={i} />;
  });

  console.log(searchResults);
  return (
    <>
      {resultsHtml && (
        <div className="container">
          <div className="row">{resultsHtml}</div>
        </div>
      )}
    </>
  );
};

const NoResults = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2 my-5">
          <p className="lead">No movies found. Please try again.</p>
        </div>
      </div>
    </div>
  );
};

const SearchView = ({ keyword, searchResults }) => {
  const title = `You are searching for ${keyword}`;
  const hasSearchResults = searchResults.length !== 0;

  return (
    <>
      <Hero text={title} />

      {hasSearchResults && MovieList({ searchResults })}
      {!hasSearchResults && NoResults()}
    </>
  );
};

export default SearchView;
