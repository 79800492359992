import Hero from "./Hero";

const HomeView = () => {
  return (
    <>
      <Hero text="FilmFlares - Find Your Favorite Movie" />

      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 my-5">
            <p className="lead">
              Welcome to FilmFlares! We are an all-in-one movie information
              catalog where you can search your favorite movies from around the
              world! Give it a try by typing your favorite movie in the search
              box on the top right of the page!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeView;
