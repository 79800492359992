import Hero from "./Hero";

const PageNotFoundView = () => {
  return (
    <>
      <Hero text="Page Not Found" />

      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 my-5">
            <p className="lead">
              Sorry, this page does not exist.
            </p>
            <p className="lead">Click here to redirect to the Home page!</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFoundView;
