import { useNavigate, Link } from "react-router-dom";

// functional component (function-based component): function that returns jsx code
// there are also class based components and functional components with "const"
// now that we put it in its own class, we are using the "const" method now

const Navbar = ({ searchText, setSearchText }) => {
  // useHistory() is now useNavigate() in react router dom v6
  // https://stackoverflow.com/a/70274942
  const navigate = useNavigate()

  const handleSearchButtonClicked = (e) => {
    e.preventDefault();
    navigate('/search')
    // navigate(`/search?query=${encodeURIComponent(searchText)}`)
  }

  const handleSearchTextChange = (e) => {
    // forces user to go to "/search" every time the user searches something
    navigate('/search')
    
    setSearchText(e.target.value)
  }

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          FilmFlares
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link disabled" aria-disabled="true" to="/">
                Coming soon!
              </Link>
            </li>
          </ul>
          <form className="d-flex" onSubmit={handleSearchButtonClicked} role="search">
            <input
              className="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              value={searchText}
              onChange={handleSearchTextChange}
            />
            <button className="btn btn-outline-success" type="submit">
              Search
            </button>
          </form>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
